<template>
  <div
    id="catalogHash"
    class="page-catalog"
  >
    <div class="container">
      <div class="page-catalog__header">
        <div class="page-catalog__nav">
          <locale-router-link
            to=""
            class="crumbs-link main-page-link"
          >
            {{ $t("home") }}</locale-router-link
          >
          <a class="crumbs-link page-catalog-link"> {{ $t("catalog.title") }}</a>
        </div>
        <h2 class="title">{{ $t("catalog.title") }}</h2>
        <p class="catalog__content">
          {{ $t("catalog.subtitle") }}
        </p>
        <hr class="page-catalog__line" />
        <form
          @submit.prevent="searchGo"
          class="page-catalog__search desktop__search"
        >
          <input
            class="page-catalog__filter-title"
            :placeholder="$t('catalog.search')"
            v-model="searchItem"
          />
          <img
            src="@/assets/img/icons/search.svg"
            alt=""
          />
        </form>
        <div
          v-if="categories.length"
          class="categories__mobile"
        >
          <button
            class="categories__mobile__item"
            v-for="(category, index) in categories"
            :key="index"
            :class="{ 'active-category': category.id === Number($route.query.category) }"
          >
            <span @click="changeCategory(category.id)">{{ category.label }}</span>
          </button>
        </div>
        <div class="mobile__buttons">
          <button
            class="page-catalog-filter"
            :class="{ 'is-active': isShowFilter }"
            @click="showFilter"
          >
            {{ $t("buttons.filter") }}
          </button>
          <div
            class="page-catalog__sorting-btn mobile__sort"
            @click="showSort"
          >
            {{ $t("buttons.sorting") }}
          </div>
        </div>
      </div>
      <div class="page-catalog__content">
        <discount-filters
          @clear-pagination="clearPaginationData"
          @load-products-by-filter="loadProductsByFilter"
          @load-products-by-search="loadProductsBySearch"
          @close-filter="isShowFilter = false"
          :isShowFilter="isShowFilter"
        />

        <div class="page-catalog__products">
          <div class="page-catalog__sorting">
            <div class="page-catalog__found">
              <span>{{ $t("catalog.found") }}</span>
              <span class="number-found-product">{{ getTotalProducts }}</span>
              <span> {{ $t("catalog.goods") }}</span>
            </div>

            <div
              class="page-catalog__sorting-btn desktop__sort"
              @click="isShowSort = !isShowSort"
            >
              <h1 v-if="sortedItem">{{ sortedItem.label }}</h1>
              <h1 v-else>{{ $t("choice") }}</h1>
              <img
                v-if="sortedItemField === 'id'"
                class="catalog__hint"
                src="@/assets/img/icons/question.svg"
                alt=""
              />
              <p class="catalog__hint__content">
                Сначала покажем лучшие скидки по версии Party Club
              </p>
              <img
                :class="{ 'is-active': isShowSort }"
                src="@/assets/img/icons/arrow-gray.svg"
                alt=""
              />
            </div>
            <discount-sort
              v-if="isShowSort"
              @sort-btn="isShowSort = false"
              @sort-start="getFiltredProducts"
              @sort-obj="getSortObj"
            />
          </div>
          <div class="page-catalog__items">
            <template v-if="countProducts">
              <product-card
                v-for="product in products"
                :key="product.id"
                :item="product"
              />
            </template>
            <div
              v-else
              class="page-catalog__items-nothing"
            >
              {{ $t("nothing") }}
            </div>
          </div>

          <button
            v-if="!isLastPage"
            class="catalog-filter-more"
            @click="nextPage"
          >
            <div
              v-if="isLoading"
              class="loader-mini__container"
            >
              <div class="loader-mini"></div>
            </div>
            <div v-else>
              {{ $t("catalog.more") }}
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex"
import { actionTypes } from "@/store/modules/catalog"
import { getAllCategoriesFiltered } from "@/api/category"

import { getSearchResults } from "@/api/search"

export default {
  name: "Catalog",
  components: {
    ProductCard: () => import("@/components/products/ProductCard.vue"),
    DiscountFilters: () => import("@/components/discount/DiscountFilters.vue"),
    DiscountSort: () => import("@/components/discount/DiscountSort.vue"),
  },
  data() {
    return {
      pagination: {
        page: 1,
        pageSize: 15,
      },
      isShowFilter: false,
      isLastPage: false,
      isShowSort: false,
      sortedItem: "",
      sortedItemField: "id",

      categories: [],
      searchItem: "",
    }
  },
  computed: {
    ...mapState("catalogModule", {
      products: "data",
      meta: "meta",
      isLoading: "isLoading",
      // mutationTypes:
    }),
    ...mapState("cityModule", {
      selectedCity: "selectedCity",
    }),
    ...mapGetters("catalogModule", ["isFilterEmpty"]),
    countProducts() {
      return this.products?.length
    },
    getTotalProducts() {
      return this?.meta?.pagination?.total || 0
    },
  },
  watch: {
    // isFilterEmpty: {
    //   handler(newVal) {
    //     if (!newVal) {
    //       this.getFiltredProducts()
    //     }
    //   },
    // },
    "$route.query.city_id": {
      handler() {
        this.getFiltredProducts({
          pagination: this.pagination,
          isPaginate: true,
        }).then(({ data, meta }) => {
          if (meta.pagination?.pageCount === this.pagination.page || data?.length === 0) {
            this.isLastPage = true
          } else {
            this.isLastPage = false
          }
        })
      },
    },
  },

  created() {
    if (this.meta.pagination?.pageCount === this.pagination.page || this.products?.length === 0) {
      this.isLastPage = true
    } else {
      this.isLastPage = false
    }
    getAllCategoriesFiltered().then((res) => {
      this.categories = res
    })
    // console.log("The id is: ", this.$route.query.category)
  },

  beforeDestroy() {
    this.clearFilters()
    const { query } = this.$route
    const qs = { city_id: query.city_id }
    this.$router.replace({
      query: qs,
    })
  },

  methods: {
    getSortObj(e) {
      this.sortedItem = e
      this.sortedItemField = e.field
    },
    ...mapActions("catalogModule", {
      getFiltredProducts: actionTypes.getFiltredProducts,
      clearFilters: actionTypes.clearFilters,
      actionLoadProductsFailure: actionTypes.actionLoadProductsFailure,
      actionLoadProductsSuccess: actionTypes.actionLoadProductsSuccess,
    }),
    showFilter() {
      this.isShowFilter = !this.isShowFilter
      this.isShowSort = false
    },
    showSort() {
      this.isShowSort = !this.isShowSort
      this.isShowFilter = false
    },
    loadProductsByFilter() {
      this.isLoading = true
      this.getFiltredProducts({
        pagination: this.pagination,
        cityId: this.$route.query.city_id,
      }).then(({ data, meta }) => {
        if (meta.pagination?.pageCount === this.pagination.page || data?.length === 0) {
          this.isLastPage = true
        } else {
          this.isLastPage = false
        }
        this.isLoading = false
      })
    },
    async loadProductsBySearch() {
      getSearchResults(
        {
          term: this.$route.query.search,
          pagination: this.pagination,
        },
        this.$route.query.city_id,
      )
        .then((res) => {
          this.actionLoadProductsSuccess({
            res: res,
            isPaginate: this.isPaginate,
          })
        })
        .catch(() => {
          this.actionLoadProductsSuccess()
        })
    },
    clearPaginationData() {
      this.pagination.page = 1
    },
    nextPage() {
      this.pagination.page++
      this.getFiltredProducts({
        pagination: this.pagination,
        isNextPage: true,
      }).then(({ data, meta }) => {
        if (meta.pagination?.pageCount === this.pagination.page || data?.length === 0) {
          this.isLastPage = true
        } else {
          this.isLastPage = false
        }
      })
    },
    changeCategory(categoryId) {
      console.log("lol")
      let query = this.$route.query
      delete query.category
      this.$router.push({
        query: {
          category: categoryId,
          ...query,
          filters: JSON.stringify(this.selectedFilters),
        },
        params: {
          isNoScroll: true,
        },
      })
    },
    searchGo() {
      if (this.searchItem.length > 0) {
        this.$router.push({ path: "catalog", query: { search: this.searchItem } })
      } else {
        this.$router.push({ path: "catalog" })
      }
    },
  },
}
</script>

<style
  lang="scss"
  scoped
>
.loader-mini__container {
  background-color: transparent;
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}
.loader-mini {
  border: 6px solid #f3f3f3;
  border-top: 6px solid #303132;
  width: 40px;
  height: 40px;
}
.loading {
  height: 100%;
  width: 100%;
  z-index: 10;
  /* position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #ffff; */
  display: flex;
  justify-content: center;
  padding-top: 100px;
}
.product-block {
  width: auto;
}
.page-catalog__items {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.catalog-filter-more {
  display: block;
  position: relative;
  min-width: 200px;
  width: auto;
  margin: 50px auto 0;
  padding: 13px 45px;
}
.categories__mobile {
  display: none;
}
@media (max-width: 900px) {
  .page-catalog__items {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 760px) {
  ::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 10px;
  }
  .active-category {
    background-color: #8344d6;
    color: white;
    border: 0;
  }
  .categories__mobile {
    display: flex;
    justify-content: space-between;
    margin: 40px 0 0;
    &__item {
      border: 1px #e8e8e8 solid;
      border-radius: 15px;
      padding: 10px 16px;
    }
  }
}
@media (max-width: 578px) {
  .page-catalog__items {
    grid-template-columns: 1fr;
  }
  .categories__mobile {
    overflow-x: scroll;
    &__item {
      min-width: 132px;
      min-height: 41px;
      white-space: nowrap;
      &:not(:last-child) {
        margin-right: 15px;
      }
      &:nth-child(2) {
        min-width: 169px;
      }
    }
  }
}
</style>
